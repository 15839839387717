<!--
 * @Author: zhangjiaheng
 * @Date: 2020-07-01 17:59:44
 * @LastEditTime: 2021-06-22 16:35:01
 * @LastEditors: Please set LastEditors
 * @Description: 消息中心体检展示
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewExamination.vue
-->
<template>
  <div>
    <en-dialog
      :visible="visiable"
      :title="content.schemeName + '体检结果'"
      en-body-scroll
      :close-on-click-modal="false"
      @close="closeDialog"
      style="text-aglin:left;"
      width="900px"
      class="examination-alert"
      :append-to-body="true"
    >
      <div class="box">
        <div class="result-head">
          <span class="icon" :style="result ? 'background:#26C393' : 'background:#F76B6B;'">
            <en-icon name="renwu" size="30" style="color:#FFFFFF;"> </en-icon>
          </span>
          <span class="tit">
            <span v-if="result">指标全部正常</span>
            <span v-else>
              <span style="color:#FFAD2C;">{{ content.checkCount.dCount }}个指标有风险</span>
              <span v-if="!result">，</span>
              <span v-if="!result" style="color:#F76B6B;">{{ content.checkCount.wCount }}个指标预警</span>
            </span>
          </span>
        </div>
        <div class="result-lis">
          <div class="row" v-for="(item, k) in content.resultContent" :key="k">
            <div class="col-rate">{{ item.field }}</div>
            <div class="col-target">指标值为{{ item.checkIndex || "" }}</div>
            <div class="col-types">
              <span :style="computedIconStyle(item)">{{ obj[item.checkStatus].txt }}</span>
              <!-- 正常 -->
              <span class="col-icon" style="background:#58CB7E;" v-if="item.checkStatus === 1">
                <en-icon name="yixuan" size="12" style="color:#FFFFFF;"></en-icon>
              </span>
              <!-- 存在风险 -->
              <span class="col-icon" v-if="item.checkStatus === 2">
                <en-icon name="tishi" size="12" style="color:#F7BF27;"></en-icon>
              </span>
              <!-- 预警显示 -->
              <span class="col-icon" v-if="item.checkStatus === 3">
                <en-icon name="tishi" size="12" style="color:#F76B6B;"></en-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <en-button @click="visiable = false">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>

<script>
export default {
  name: "viewExamination",
  props: {
    // 体检展示数据
    content: {
      type: Object,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiable: false,
      obj: {
        1: { txt: "正常", color: "#636C78" },
        2: { txt: "存在风险", color: "#F78528" },
        3: { txt: "预警提示", color: "#F54646" }
      }
    };
  },
  computed: {
    // 判断指标 正常还是异常
    result() {
      if (this.content.checkCount.dCount === 0 && this.content.checkCount.wCount === 0) {
        // 如果 风险 和危险 都为 0
        // 就是 正常
        return true;
      }
      // 异常
      return false;
    },
    // 指标值具体样式
    computedIconStyle() {
      return (item) => `color:${this.obj[item.checkStatus].color};${item.checkStatus === 1 ? "" : "text-decoration:underline;"}`;
    }
  },
  mounted() {
    this.visiable = this.show;
  },
  methods: {
    // 关闭回调
    closeDialog() {
      this.$emit("toShow", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.examination-alert {
  /deep/.el-dialog__body {
    padding: 0 !important;
  }
}
.box {
  width: 100%;
  min-height: 400px;
  font-size: 12px;
  line-height: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(99, 108, 120, 1);
  .result-head {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #e8ecf2;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .icon {
      width: 45px;
      height: 45px;
      background: red;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }
    .tit {
      height: 45px;
      line-height: 45px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
    }
  }
  .row {
    display: flex;
    height: 50px;
    line-height: 50px;
    margin: 0 20px;
    border-bottom: 1px solid #f3f3f3;
    padding-left: 20px;
    position: relative;
    .col-rate {
      width: 130px;
      margin-right: 10px;
    }
    .col-target {
      flex: 1;
      min-width: 130px;
      margin-right: 10px;
    }
    .col-types {
      width: 120px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .col-icon {
      margin-left: 16px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .row::before {
    content: "";
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: #49bef2;
    position: absolute;
    left: 0;
    top: 22px;
  }
}
</style>

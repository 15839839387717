var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "en-dialog",
        {
          staticClass: "examination-alert",
          staticStyle: { "text-aglin": "left" },
          attrs: {
            visible: _vm.visiable,
            title: _vm.content.schemeName + "体检结果",
            "en-body-scroll": "",
            "close-on-click-modal": false,
            width: "900px",
            "append-to-body": true,
          },
          on: { close: _vm.closeDialog },
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "result-head" }, [
              _c(
                "span",
                {
                  staticClass: "icon",
                  style: _vm.result
                    ? "background:#26C393"
                    : "background:#F76B6B;",
                },
                [
                  _c("en-icon", {
                    staticStyle: { color: "#FFFFFF" },
                    attrs: { name: "renwu", size: "30" },
                  }),
                ],
                1
              ),
              _c("span", { staticClass: "tit" }, [
                _vm.result
                  ? _c("span", [_vm._v("指标全部正常")])
                  : _c("span", [
                      _c("span", { staticStyle: { color: "#FFAD2C" } }, [
                        _vm._v(
                          _vm._s(_vm.content.checkCount.dCount) + "个指标有风险"
                        ),
                      ]),
                      !_vm.result ? _c("span", [_vm._v("，")]) : _vm._e(),
                      !_vm.result
                        ? _c("span", { staticStyle: { color: "#F76B6B" } }, [
                            _vm._v(
                              _vm._s(_vm.content.checkCount.wCount) +
                                "个指标预警"
                            ),
                          ])
                        : _vm._e(),
                    ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "result-lis" },
              _vm._l(_vm.content.resultContent, function (item, k) {
                return _c("div", { key: k, staticClass: "row" }, [
                  _c("div", { staticClass: "col-rate" }, [
                    _vm._v(_vm._s(item.field)),
                  ]),
                  _c("div", { staticClass: "col-target" }, [
                    _vm._v("指标值为" + _vm._s(item.checkIndex || "")),
                  ]),
                  _c("div", { staticClass: "col-types" }, [
                    _c("span", { style: _vm.computedIconStyle(item) }, [
                      _vm._v(_vm._s(_vm.obj[item.checkStatus].txt)),
                    ]),
                    item.checkStatus === 1
                      ? _c(
                          "span",
                          {
                            staticClass: "col-icon",
                            staticStyle: { background: "#58CB7E" },
                          },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#FFFFFF" },
                              attrs: { name: "yixuan", size: "12" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.checkStatus === 2
                      ? _c(
                          "span",
                          { staticClass: "col-icon" },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#F7BF27" },
                              attrs: { name: "tishi", size: "12" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    item.checkStatus === 3
                      ? _c(
                          "span",
                          { staticClass: "col-icon" },
                          [
                            _c("en-icon", {
                              staticStyle: { color: "#F76B6B" },
                              attrs: { name: "tishi", size: "12" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "en-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visiable = false
                    },
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }